import { getEnvVar } from '../utilities/helpers'

export const CLIENT_SECRET = getEnvVar('REACT_APP_CLIENT_SECRET')
export const CLIENT_ID = getEnvVar('REACT_APP_CLIENT_ID')
export const API_APP = getEnvVar('REACT_APP_API_APP')
export const API_CLIENT = process.env.REACT_APP_API_KEYCLOAK_REALM
export const API_TENANT = process.env.REACT_APP_API_TENANT
export const API_SUB_TENANT = process.env.REACT_APP_API_SUB_TENANT
export const CHAT_API_URL = getEnvVar('REACT_APP_CHAT_API_URL')
export const WEB_SOCKET_URL = getEnvVar('REACT_APP_WEB_SOCKET_URL')
export const API_BASE_URL = getEnvVar('REACT_APP_API_BASE_URL')
export const API_BACKEND_URL = getEnvVar('REACT_APP_API_BACKEND')
export const API_REFERENCE_VALUE_URL = getEnvVar(
  'REACT_APP_API_REFERENCE_VALUE'
)
export const API_SURVEY_URL = getEnvVar('REACT_APP_API_SURVEY_CORE_URL')
export const API_RULE_URL = getEnvVar('REACT_APP_API_RULE_URL')
export const KEYCLOAK_URL = getEnvVar('REACT_APP_API_KEYCLOAK_URL')
export const KEYCLOAK_REALM = getEnvVar('REACT_APP_API_KEYCLOAK_REALM')
export const KEYCLOAK_CLIENT_ID: string =
  getEnvVar('REACT_APP_API_KEYCLOAK_CLIENT_ID') ?? ''
export const KEYCLOAK_CLIENT_UUID = getEnvVar(
  'REACT_APP_API_KEYCLOAK_CLIENT_UUID'
)
export const MEDICAL_CHECKUP_MASTER_EXAMPLE: string = getEnvVar(
  'REACT_APP_MEDICAL_CHECKUP_MASTER'
)

export const OVERALL_VERDICT_CATEGORY_EXAMPLE: string = getEnvVar(
  'REACT_APP_OVERALL_VERDICT_CATEGORY'
)
export const API_CLIENT_CODE = getEnvVar('REACT_APP_API_KEYCLOAK_REALM')

export const API_RESERVATION_GRAPHQL_URL = getEnvVar(
  'REACT_APP_API_RESERVATION_GRAPHQL_URL'
)
export const API_RESERVATION_API_URL = getEnvVar(
  'REACT_APP_API_RESERVATION_URL'
)

// Sentry
export const SENTRY_DSN = getEnvVar('REACT_APP_SENTRY_DSN')

// define api endpoint here
export const API_LOGIN_V1 = '/api/user/login'
export const API_LOGIN_V2 = '/api/user/v2/login'
export const API_LOGIN_CLIENT_TOKEN = '/api/keycloak/client/token'

export const API_FORGOT_PASSWORD = '/api/password/otp/request'
export const API_PASSWORD_VERIFY_CODE = '/api/password/otp/confirmation'
export const API_RESET_PASSWORD = '/api/password/reset'

// Keycloak API
export const REALM_API_TOKEN = `realms/${KEYCLOAK_REALM}/protocol/openid-connect/token`
export const API_USER = `admin/realms/${KEYCLOAK_REALM}/users`
export const ROLE_LIST = `admin/realms/${KEYCLOAK_REALM}/clients/${KEYCLOAK_CLIENT_UUID}/roles`
export const ROLE_MAPPING = `admin/realms/${KEYCLOAK_REALM}/users/USER_ID/role-mappings`
export const ROLE_DETAIL = `admin/realms/${KEYCLOAK_REALM}/roles-by-id`
export const ROLE_UPDATE = `admin/realms/${KEYCLOAK_REALM}/clients/${KEYCLOAK_CLIENT_UUID}/roles`
export const ASSIGNED_GROUP_LIST = `admin/realms/${KEYCLOAK_REALM}/users/USER_ID/groups`
export const GROUP_DETAIL = `admin/realms/${KEYCLOAK_REALM}/groups`
export const GROUP_LIST = `admin/realms/${KEYCLOAK_REALM}/ui-ext/groups`

//chat
export const API_GET_ROCKET_USER = `${CHAT_API_URL}/api/v1/me`
export const API_GET_GROUP_ROOMS = `${CHAT_API_URL}/api/v1/groups.list`
export const API_GET_DIRECT_ROOMS = `${CHAT_API_URL}/api/v1/im.list`
export const API_GET_DIRECT_FILES = `${CHAT_API_URL}/api/v1/im.files`
export const API_GET_GROUP_FILES = `${CHAT_API_URL}/api/v1/groups.files`
export const API_GET_CHAT_ROOM = `${CHAT_API_URL}/api/v1/method.call/getRoomByTypeAndName`
export const API_GET_MESSAGE_DETAIL = `${CHAT_API_URL}/api/v1/chat.getMessage`
export const API_POST_MESSAGE = `${CHAT_API_URL}/api/v1/chat.postMessage`
export const API_CREATE_DIRECT_ROOM = `${CHAT_API_URL}/api/v1/dm.create`

export const API_HIDE_CHAT_ROOM = `${CHAT_API_URL}/api/v1/method.call/hideRoom`
export const API_OPEN_CHAT_ROOM = `${CHAT_API_URL}/api/v1/method.call/openRoom`
export const API_TOGGLE_FAVORITE = `${CHAT_API_URL}/api/v1/method.call/toggleFavorite`
export const API_LEAVE_ROOM = `${CHAT_API_URL}/api/v1/method.call/leaveRoom`
export const API_GET_ROOMS = `${CHAT_API_URL}/api/v1/method.call/subscriptions%3Aget`
export const API_GET_ALL_ROOMS = `${CHAT_API_URL}/api/v1/method.call/rooms%3Aget`
export const API_SEND_MESSAGES = `${CHAT_API_URL}/api/v1/method.call/sendMessage`
export const API_READ_MESSAGES = `${CHAT_API_URL}/api/v1/method.call/readMessages`
export const API_SET_REACTION = `${CHAT_API_URL}/api/v1/method.call/setReaction`
export const API_SEARCH_MESSAGES = `${CHAT_API_URL}/api/v1/method.call/rocketchatSearch.search`
export const API_LOAD_NEXT_MESSAGES = `${CHAT_API_URL}/api/v1/method.call/loadNextMessages`
export const API_LOAD_SURROUND_MESSAGES = `${CHAT_API_URL}/api/v1/method.call/loadSurroundingMessages`
export const API_GET_DIRECT_MESSAGES = `${CHAT_API_URL}/api/v1/im.history`
export const API_LOAD_HISTORY_MESSAGES = `${CHAT_API_URL}/api/v1/method.call/loadHistory`
export const API_ADD_MEMBERS = `${CHAT_API_URL}/api/v1/method.call/addUsersToRoom`
export const API_GET_GROUP_MEMBERS = `${CHAT_API_URL}/api/v1/groups.members`
export const API_GET_ACTIVE_USERS = `${CHAT_API_URL}/api/v1/users.presence`
export const API_KICK_USER_GROUP = `${CHAT_API_URL}/api/v1/groups.kick`
export const API_GET_LINKS_GROUP = `${CHAT_API_URL}/api/v1/groups.messages`
export const API_GET_LINKS_DIRECT = `${CHAT_API_URL}/api/v1/im.messages`
export const API_UPDATE_GROUP_INFO = `${CHAT_API_URL}/chat/group-chats`
export const API_ADD_OWNER_GROUP = `${CHAT_API_URL}/api/v1/groups.addOwner`
export const API_REMOVE_OWNER_GROUP = `${CHAT_API_URL}/api/v1/groups.removeOwner`
export const API_GET_ROOM_ROLES = `${CHAT_API_URL}/api/v1/method.call/getRoomRoles`
export const API_GET_SLASH_COMMAND = `${CHAT_API_URL}/api/v1/method.call/slashCommand`
export const API_SEND_FILE_MESSAGE = `${CHAT_API_URL}/api/v1/rooms.upload`
export const API_VOTE_MESSAGE = `${CHAT_API_URL}/api/apps/ui.interaction`
export const API_GET_CHANNELS_JOINED = `${CHAT_API_URL}/api/v1/channels.list.joined`
export const API_GET_SUBSCRIPTIONS = `${CHAT_API_URL}/api/v1/subscriptions.get`
export const API_GET_MESSAGES_IN_ROOM = `${CHAT_API_URL}/api/v1/method.call/loadHistory`
export const API_GET_USER_INFO = `${CHAT_API_URL}/api/v1/me`

export const API_GET_ROOM_ROCKET = '/rooms.adminRooms'
