import React, { Suspense, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Routes } from 'react-router-dom'

import { ConfigProvider, Spin, notification } from 'antd'
import dayjs from 'dayjs'
import { RecoilRoot } from 'recoil'

import './App.css'
import { ProtectedRoute } from './components/ProtectedRoute'
import './configs/recoil'
import AppApolloProvider from './context/ApolloProvider'
import AuthProvider from './context/AuthProvider'
import PublicLayout from './layouts/PublicLayout'
import emitter from './models/emitter'
import loadable from '@loadable/component'
import en from 'antd/locale/en_US'
import ja from 'antd/locale/ja_JP'
import vi from 'antd/locale/vi_VN'
import { MAX_TIME_TO_RETRY, STORAGE_KEYS } from 'configs/constant'
import { ASSESSMENT_COMMENT, OPTION_FORM } from 'configs/pathRouter'
import { connectToRocketChat, disconnectToRocketChat } from 'configs/rocketChat'
import 'dayjs/locale/en'
import 'dayjs/locale/ja'
import 'dayjs/locale/vi'
import { useRolePermissions } from 'hooks/role-permission/usePermissions'
import AuthenticationLayout from 'layouts/AuthenticationLayout'
import HomeLayout from 'layouts/HomeLayout/HomeLayout'

const Loading = () => (
  <div className="flex justify-center items-center h-[100vh] w-full">
    <Spin spinning={true} />
  </div>
)

const ConsultantManagement = loadable(
  () => import(/* webpackPrefetch: true */ './pages/ConsultantManagement'),
  { fallback: <Loading /> }
)
const ImportCustomerList = loadable(
  () => import(/* webpackPrefetch: true */ './pages/CustomerImport'),
  { fallback: <Loading /> }
)
const CustomerRegister = loadable(
  () => import(/* webpackPrefetch: true */ './pages/CustomerRegister'),
  { fallback: <Loading /> }
)
const InterviewResultCreate = loadable(
  () => import(/* webpackPrefetch: true */ './pages/InterviewResultCreate'),
  { fallback: <Loading /> }
)
const JudgementSetting = loadable(
  () => import(/* webpackPrefetch: true */ './pages/JudgementSetting'),
  { fallback: <Loading /> }
)
const MonthlyConsultationReport = loadable(
  () => import(/* webpackPrefetch: true */ './pages/MonthlyConsultationReport'),
  { fallback: <Loading /> }
)
const ProductDuplication = loadable(
  () => import(/* webpackPrefetch: true */ './pages/ProductDuplication'),
  { fallback: <Loading /> }
)
const ProductManagement = loadable(
  () => import(/* webpackPrefetch: true */ './pages/ProductManagement'),
  { fallback: <Loading /> }
)
const ProductSetting = loadable(
  () => import(/* webpackPrefetch: true */ './pages/ProductSetting'),
  { fallback: <Loading /> }
)
const ReservationChange = loadable(
  () => import(/* webpackPrefetch: true */ './pages/ReservationChange'),
  { fallback: <Loading /> }
)
const ReservationManagement = loadable(
  () => import(/* webpackPrefetch: true */ './pages/ReservationManagement'),
  { fallback: <Loading /> }
)
const ReservationRegister = loadable(
  () => import(/* webpackPrefetch: true */ './pages/ReservationRegister'),
  { fallback: <Loading /> }
)
const RuleLogical = loadable(
  () => import(/* webpackPrefetch: true */ './pages/RuleLogical'),
  { fallback: <Loading /> }
)
const SurveyCreation = loadable(
  () => import(/* webpackPrefetch: true */ './pages/SurveyCreation'),
  { fallback: <Loading /> }
)
const SurveyEdit = loadable(
  () => import(/* webpackPrefetch: true */ './pages/SurveyEdit'),
  { fallback: <Loading /> }
)
const SurveyList = loadable(
  () => import(/* webpackPrefetch: true */ './pages/SurveyList'),
  { fallback: <Loading /> }
)
const YearlyConsultationReport = loadable(
  () => import(/* webpackPrefetch: true */ './pages/YearlyConsultationReport'),
  { fallback: <Loading /> }
)
const AdminList = loadable(
  () => import(/* webpackPrefetch: true */ './pages/AdminList'),
  { fallback: <Loading /> }
)
const AdminRegister = loadable(
  () => import(/* webpackPrefetch: true */ './pages/AdminRegister'),
  { fallback: <Loading /> }
)
const BasicSetting = loadable(
  () => import(/* webpackPrefetch: true */ './pages/BasicSetting'),
  { fallback: <Loading /> }
)
const ChatPage = loadable(
  () => import(/* webpackPrefetch: true */ './pages/Chat/Chat'),
  { fallback: <Loading /> }
)
const CourseRegister = loadable(
  () => import(/* webpackPrefetch: true */ './pages/CourseRegister'),
  { fallback: <Loading /> }
)
const CsvImportResults = loadable(
  () => import(/* webpackPrefetch: true */ './pages/CsvImportResults'),
  { fallback: <Loading /> }
)
const CustomerDetail = loadable(
  () => import(/* webpackPrefetch: true */ './pages/CustomerDetail'),
  { fallback: <Loading /> }
)
const CustomerManagementWrapper = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './pages/CustomerManagement/CustomerManagementWrapper'
    ),
  { fallback: <Loading /> }
)
const ForgotPassWord = loadable(
  () => import(/* webpackPrefetch: true */ './pages/ForgotPassWord'),
  { fallback: <Loading /> }
)
const InspectionItemSetting = loadable(
  () => import(/* webpackPrefetch: true */ './pages/InspectionItemSetting'),
  { fallback: <Loading /> }
)
const Login = loadable(
  () => import(/* webpackPrefetch: true */ './pages/Login'),
  { fallback: <Loading /> }
)
const MyPage = loadable(
  () => import(/* webpackPrefetch: true */ './pages/MyPage'),
  { fallback: <Loading /> }
)
const PasswordResetSuccess = loadable(
  () => import(/* webpackPrefetch: true */ './pages/PasswordResetSuccess'),
  { fallback: <Loading /> }
)
const PasswordVerifyCode = loadable(
  () => import(/* webpackPrefetch: true */ './pages/PasswordVerifyCode'),
  { fallback: <Loading /> }
)
const ProductMaster = loadable(
  () => import(/* webpackPrefetch: true */ './pages/ProductMaster'),
  { fallback: <Loading /> }
)
const OptionForm = loadable(
  () => import(/* webpackPrefetch: true */ './pages/ProductMaster/optionForm'),
  { fallback: <Loading /> }
)
const ReservationDetail = loadable(
  () =>
    import(
      /* webpackPrefetch: true */ './pages/Reservation/views/ReservationDetail'
    ),
  { fallback: <Loading /> }
)
const ResetPassword = loadable(
  () => import(/* webpackPrefetch: true */ './pages/ResetPassword'),
  { fallback: <Loading /> }
)
const ScenarioCreate = loadable(
  () => import(/* webpackPrefetch: true */ './pages/Scenario/ScenarioCreate'),
  { fallback: <Loading /> }
)
const ScenarioList = loadable(
  () => import(/* webpackPrefetch: true */ './pages/Scenario/ScenarioList'),
  { fallback: <Loading /> }
)
const SelectTenant = loadable(
  () => import(/* webpackPrefetch: true */ './pages/SelectTenant/SelectTenant'),
  { fallback: <Loading /> }
)
const SurveyDetail = loadable(
  () => import(/* webpackPrefetch: true */ './pages/SurveyDetail'),
  { fallback: <Loading /> }
)

const language: any = {
  vi: vi,
  ja: ja,
  en: en
}

function App() {
  let retryConnectRocket = MAX_TIME_TO_RETRY

  const { i18n } = useTranslation()

  notification.config({
    closeIcon: false,
    duration: 3,
    maxCount: 5
  })

  useEffect(() => {
    try {
      const { adminToken, adminUserId } = JSON.parse(
        localStorage.getItem(STORAGE_KEYS.ADMIN_ROCKETCHAT) || '{}'
      )

      if (adminToken && adminUserId) {
        connectToRocketChat()
        return () => {
          disconnectToRocketChat()
        }
      }
    } catch (err) {
      console.error(err)
    }
  }, [])

  useEffect(() => {
    // @ts-ignore
    emitter.on('SHOULD_RELOAD', onReloadPage)
    return () => {
      // @ts-ignore
      emitter.off('SHOULD_RELOAD', onReloadPage)
    }
  }, [])

  const onReloadPage = (shouldReload: any) => {
    if (shouldReload && retryConnectRocket > 0) {
      connectToRocketChat()
      retryConnectRocket = retryConnectRocket - 1
    }
  }

  useEffect(() => {
    dayjs.locale(i18n.language ?? 'ja')
  }, [i18n.language])

  return (
    <AppApolloProvider>
      <ConfigProvider
        locale={language[i18n.language ?? 'ja']}
        theme={{
          token: {
            colorPrimary: '#137695',
            colorTextPlaceholder: '#D9E0E5',
            colorTextBase: '#545454',
            borderRadius: 2,
            controlHeight: 30,
            colorBgMask: '#00000057'
          },
          components: {
            Button: {
              colorPrimary: '#137695'
            },
            Input: {
              colorPrimary: '#137695'
            },
            Typography: {
              colorPrimary: '#137695'
            },
            Radio: {
              buttonColor: '#BDCBD5',
              buttonSolidCheckedHoverBg: '#DF2475',
              buttonSolidCheckedBg: '#DF2475'
            },
            Tree: {
              nodeHoverBg: 'transparent'
            },
            Layout: {
              siderBg:
                'transparent linear-gradient(180deg, #127594 0%, #93D1CA 100%) 0% 0% no-repeat padding-box;'
            }
          }
        }}
      >
        <RecoilRoot>
          <Suspense
            fallback={
              <div className="flex justify-center items-center h-[100vh] w-full">
                <Spin spinning={true} />
              </div>
            }
          >
            <AuthProvider>
              <Routes>
                <Route path="/" element={<HomeLayout />}>
                  <Route index element={<DefaultDisplay />} />
                  <Route path="setting" element={<></>} />
                  <Route
                    path="consultant-management"
                    element={
                      <ProtectedRoute
                        permission="medical_survey_feedback"
                        children={<ConsultantManagement />}
                      />
                    }
                  />
                  <Route
                    path="survey/:refid/detail"
                    element={
                      <ProtectedRoute
                        permission="medical_survey"
                        children={<SurveyDetail />}
                      />
                    }
                  />
                  <Route
                    path="survey/:refid/edit"
                    element={
                      <ProtectedRoute
                        permission="medical_survey"
                        children={<SurveyEdit />}
                      />
                    }
                  />
                  <Route
                    path="survey-creation"
                    element={
                      <ProtectedRoute
                        permission="medical_survey"
                        children={<SurveyCreation />}
                      />
                    }
                  />
                  <Route path="basic-setting" element={<BasicSetting />} />
                  <Route
                    path="inspection-item-settings"
                    element={
                      <ProtectedRoute
                        permission="setting_inspection"
                        children={<InspectionItemSetting />}
                      />
                    }
                  />
                  <Route
                    path={ASSESSMENT_COMMENT}
                    element={<JudgementSetting />}
                  />
                  <Route path="inspection-setting" element={<></>} />
                  <Route
                    path="admin-list"
                    element={
                      <ProtectedRoute
                        permission="setting_role"
                        children={<AdminList />}
                      />
                    }
                  />
                  <Route path="admin-register" element={<AdminRegister />} />
                  <Route
                    path="course-register"
                    element={
                      <ProtectedRoute
                        permission="setting_product"
                        children={<CourseRegister />}
                      />
                    }
                  />
                  <Route path={OPTION_FORM} element={<OptionForm />} />
                  <Route
                    path="product-master"
                    element={
                      <ProtectedRoute
                        permission="setting_product"
                        children={<ProductMaster />}
                      />
                    }
                  />
                  <Route path="chat" element={<ChatPage />} />
                  <Route
                    path="interview-result-create"
                    element={<InterviewResultCreate />}
                  />
                  <Route
                    path="customer-management"
                    element={
                      <ProtectedRoute
                        permission="customer_management"
                        children={<CustomerManagementWrapper />}
                      />
                    }
                  />
                  <Route
                    path="customer-import"
                    element={
                      <ProtectedRoute
                        permission="customer_management"
                        children={<ImportCustomerList />}
                      />
                    }
                  />
                  <Route
                    path="reservation-management"
                    element={
                      <ProtectedRoute
                        permission="reservation_management"
                        children={<ReservationManagement />}
                      />
                    }
                  />
                  <Route
                    path="reservation-register"
                    element={
                      <ProtectedRoute
                        permission="reservation_management"
                        children={<ReservationRegister />}
                      />
                    }
                  />
                  <Route
                    path="reservation-change"
                    element={
                      <ProtectedRoute
                        permission="reservation_management"
                        children={<ReservationChange />}
                      />
                    }
                  />
                  <Route
                    path="/reservation-detail"
                    element={
                      <ProtectedRoute
                        permission="reservation_management"
                        children={<ReservationDetail />}
                      />
                    }
                  />
                  <Route
                    path="product-management"
                    element={
                      <ProtectedRoute
                        permission="setting_product"
                        children={<ProductManagement />}
                      />
                    }
                  />
                  <Route
                    path="product-setting"
                    element={
                      <ProtectedRoute
                        permission="setting_product"
                        children={<ProductSetting />}
                      />
                    }
                  />
                  <Route
                    path="product-duplication"
                    element={
                      <ProtectedRoute
                        permission="setting_product"
                        children={<ProductDuplication />}
                      />
                    }
                  />
                  <Route
                    path="yearly-consultation-report"
                    element={
                      <ProtectedRoute
                        permission="health_support_claim"
                        children={<YearlyConsultationReport />}
                      />
                    }
                  />
                  <Route
                    path="monthly-consultation-report"
                    element={
                      <ProtectedRoute
                        permission="health_support_claim"
                        children={<MonthlyConsultationReport />}
                      />
                    }
                  />
                  <Route
                    path="customer-detail"
                    element={
                      <ProtectedRoute
                        permission="customer_management"
                        children={<CustomerDetail />}
                      />
                    }
                  />
                  <Route
                    path="customer-register"
                    element={
                      <ProtectedRoute
                        permission="customer_management"
                        children={<CustomerRegister />}
                      />
                    }
                  />
                  <Route path="my-page" element={<MyPage />} />
                  <Route
                    path="survey-list"
                    element={
                      <ProtectedRoute
                        permission="medical_survey"
                        children={<SurveyList />}
                      />
                    }
                  />
                  <Route path="/scenario" element={<ScenarioList />}></Route>
                  <Route
                    path="/scenario/create"
                    element={<ScenarioCreate />}
                  ></Route>
                  <Route
                    path="/scenario/:refId/edit"
                    element={<ScenarioCreate />}
                  ></Route>
                  <Route
                    path="csv-import-results"
                    element={<CsvImportResults />}
                  />
                </Route>
                <Route element={<AuthenticationLayout />}>
                  <Route
                    path="/select-tenant"
                    element={<SelectTenant />}
                  ></Route>
                  <Route path="/login" element={<Login />}></Route>
                  <Route
                    path="/forgot-password"
                    element={<ForgotPassWord />}
                  ></Route>
                  <Route
                    path="/password-verify-code"
                    element={<PasswordVerifyCode />}
                  ></Route>
                  <Route
                    path="/reset-password"
                    element={<ResetPassword />}
                  ></Route>
                  <Route
                    path="/password-reset-success"
                    element={<PasswordResetSuccess />}
                  ></Route>
                </Route>
                <Route element={<PublicLayout />}>
                  <Route path="/rule-logical" element={<RuleLogical />} />
                </Route>
              </Routes>
            </AuthProvider>
          </Suspense>
        </RecoilRoot>
      </ConfigProvider>
    </AppApolloProvider>
  )
}

const DefaultDisplay = () => {
  const { hasPermissions } = useRolePermissions()

  if (hasPermissions('customer_management'))
    return <CustomerManagementWrapper />
  if (hasPermissions('medical_survey_feedback')) return <ConsultantManagement />

  return <SurveyList />
}

export default App
