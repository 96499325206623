import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import App from './App'
import './instrument'
import reportWebVitals from './reportWebVitals'
import 'i18n/i18n'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

// hide loading screen when app loaded
function hideLoadingScreen() {
  const loadingScreen = document.getElementById('loading-screen')
  if (loadingScreen) {
    loadingScreen.style.display = 'none'
  }
}

function AppWrapper() {
  React.useEffect(() => {
    hideLoadingScreen()
  }, [])

  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  )
}

root.render(
  // <React.StrictMode>
  <AppWrapper />

  //  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
