// define constant here
export const ERROR_AUTHORIZE_CODE = 401
export const ERROR_PATH = '/error'
export const REQUEST_TIMEOUT = 30000
export const AND_CONJUNCTION: string = ';'
export const STORAGE_TOKEN_KEY = 'TOKEN'
export const STORAGE_TENANT_KEY = '@tenant'
export const STORAGE_TOKEN_CLIENT_KEY = 'TOKEN_CLIENT'
export const STORAGE_REFRESH_TOKEN_KEY = 'REFRESH_TOKEN'
export const PAGE_SIZE = 20
export const STATUS_CODE_SUCCESS = 'S0000'
export const STATUS_CODE_ALREADY_EXISTS = 'E40091'
export const PHONE_REGEX = /[^0-9]/g
export const DATE_FORMAT_YMD_SLASH = 'yyyy/MM/dd'

export const PATIENT_ID_CODE = '1.00004'
export const MESSAGE_LIMIT = 100
export const MAX_TIME_TO_RETRY = 10

export const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd'

export const PHONE_NUMBER_REGEX = /^\+?[0-9]{7,15}$/

export const SORT_ORDER = {
  ASC: 'asc',
  DESC: 'desc'
}

export enum ConditionalNumber {
  GE = 'ge', // 以上
  LE = 'le' // 以下
}

export enum typeReferenceValue {
  STRING = 'string',
  CONDITIONAL_NUMBER = 'conditionalNumber',
  RANGE_NUMBER = 'rangeNumber'
}

export enum GENDER {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  NONE = 'NONE'
}

export enum CLASSIFICATION {
  INDIVIDUAL = 'Individual',
  ORGANIZATION = 'Organization'
}

export enum FEEDBACK_STATUS {
  ASSIGNED = 'ASSIGNED',
  DRAFT = 'DRAFT',
  COMPLETED = 'COMPLETED',
  DELETED = 'DELETED'
}

export enum FEEDBACK_LIST_TOTAL {
  ALL = 'ALL',
  ASSIGNED = 'ASSIGNED',
  COMPLETED = 'COMPLETED',
  UNANSWERED = 'UNANSWERED'
}

export enum PRODUCT_LIST_TOTAL {
  COURSE = 'COURSE',
  OPTION = 'OPTION'
}

export const VALUE_WITH_NO_DATA = '-'

export enum CONSULTANT_SORT_TYPE {
  USER_ID = 'refId',
  USER_NAME = 'name',
  USER_DATE_OF_BIRTH = 'birthday',
  USER_AGE = 'age',
  USER_GENDER = 'gender',
  SURVEY_FEEDBACK_TITLE = 'title',
  SURVEY_FEEDBACK_EXAM_DATE = 'examDate',
  SURVEY_FEEDBACK_STATUS = 'feedbackStatus',
  SURVEY_FEEDBACK_CREATE_AT = 'createdDateTime'
}

export enum PRODUCT_MASTER_SORT_TYPE {
  NAME = 'displayName',
  SUBSCRIBER = 'createdBy',
  REGISTRATION_DATE = 'createdDate'
}

export enum ADMIN_LIST_SORT_TYPE {
  FULL_NAME = 'fullName',
  SEX = 'sex',
  DEPARTMENT = 'departments',
  ASSIGNMENT = 'assignments',
  CREATED_TIMESTAMP = 'createdTimestamp'
}

export enum MEDICAL_CHECKUP_STATUS {
  NOT_COMPATIBLE = 0,
  RESULTS_IN_PROGRESS = 1,
  AWAITING_JUDGEMENT = 2,
  JUDGMENT_COMPLETE = 3,
  SUBMITTED = 4
}

export enum RESOURCES_TENANT {
  DEPARTMENTS = 'departments',
  POSITIONS = 'positions',
  ASSIGNMENTS = 'assignments'
}

export enum OPERATION_TYPE {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE'
}

export const MEDICAL_CHECKUP_STATUS_TEXT: { [key: number]: string } = {
  0: 'customerManagement.result.incompatible',
  1: 'customerManagement.result.creating',
  2: 'customerManagement.result.waiting',
  3: 'customerManagement.result.ended',
  4: 'customerManagement.result.sent'
}

export const weekdayOptions = [
  { label: 'day.short_monday', value: 'Monday', order: 0 },
  { label: 'day.short_tuesday', value: 'Tuesday', order: 1 },
  { label: 'day.short_wednesday', value: 'Wednesday', order: 2 },
  { label: 'day.short_thursday', value: 'Thursday', order: 3 },
  { label: 'day.short_friday', value: 'Friday', order: 4 },
  { label: 'day.short_saturday', value: 'Saturday', order: 5 },
  { label: 'day.short_sunday', value: 'Sunday', order: 6 },
  { label: 'day.short_holiday', value: 'Holiday', order: 7 }
]

export const WeekDayLabels = {
  Monday: 'day.monday',
  Tuesday: 'day.tuesday',
  Wednesday: 'day.wednesday',
  Thursday: 'day.thursday',
  Friday: 'day.friday',
  Saturday: 'day.saturday',
  Sunday: 'day.sunday',
  Holiday: 'day.holiday'
}

export enum statusRoom {
  OPEN = 'OPEN',
  END = 'COMPLETED'
}

export enum readingTypeItemMaster {
  STRING = 'STRING',
  NUMERICAL = 'NUMERICAL'
}

export enum PATHS {
  PRODUCT_MANAGEMENT = '/product-management',
  PRODUCT_SETTING = '/product-setting',
  MONTHLY_CONSULTATION_REPORT = '/monthly-consultation-report',
  RESERVATION_MANAGEMENT = '/reservation-management',
  RESERVATION_CHANGE = '/reservation-change'
}

export const uniqueTimes = [
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
  '24:00',
  '01:00',
  '02:00'
]

export const chatStatus = {
  DONE: 'done',
  IN_PROGRESS: 'process'
}

// Add this new constant
export const ATTACHMENT_TYPES = {
  DROPDOWN: 'dropdown',
  BUTTON: 'button',
  DATEPICKER: 'datepicker',
  SURVEY_FEEDBACK: 'survey_feedback'
}

export const META_DATA_MEDICAL_CHECKUP_MASTER = {
  OVERVIEW: 'overview',
  SEX: 'sex',
  CLASSIFICATION: 'classification',
  START_AGE: 'startAge',
  END_AGE: 'endAge',
  COURSE_TIMES: 'courseTimes',
  BUFFER: 'buffer',
  SURVEYS: 'surveys',
  OPTIONS: 'options',
  COURSE_CD: 'courseCd'
}

export const LIST_DRAG_AND_DROP = {
  LIST1: 'list1',
  LIST2: 'list2',
  CATEGORY: 'category',
  ITEM: 'item',
  SEPARATOR: '_'
}

export const genderOptions = [
  { key: '1', label: '男性', value: 'MALE' },
  { key: '2', label: '女性', value: 'FEMALE' }
]

// Options for the number of records to display per page
export const optionsPerPage = [
  { value: 50, label: '50' },
  { value: 100, label: '100' },
  { value: 200, label: '200' },
  { value: 500, label: '500' }
]

export enum SELECTION_TYPE {
  RESERVATION = 'RESERVATION',
  BUTTON = 'BUTTON',
  CHOICE = 'CHOICE',
  DATE = 'DATE',
  TEXT = 'TEXT',
  SURVEY_FEEDBACK = 'SURVEY_FEEDBACK',
  BUTTON_REGISTRATION = 'BUTTON_REGISTRATION',
  RADIO_BUTTON = 'RADIO_BUTTON',
  CHOICE_OPTION = 'CHOICE_OPTION',
  MSG_FALLBACK = 'MSG_FALLBACK'
}

export enum MODAL_TYPE {
  CHOOSE_TYPE_SCENARIO = 'chooseTypeScenario',
  DELETED_BLOCK = 'deletedBlock',
  DELETE_BLOCK = 'deleteBlock',
  CREATE_SCENARIO = 'createScenario',
  CREATE_SCENARIO_TEMP = 'createScenarioTemp'
}

export enum FIXED_TEXT_TYPE {
  START_ACTIVATING = 'startActivating',
  LAST_STEP = 'lastStep'
}

export enum STEP_SCENARIO {
  FIRST_STEP = 0,
  SECOND_STEP = 1,
  THIRD_STEP = 2,
  FOURTH_STEP = 3,
  LAST_STEP = 4
}

export enum HEADER_BUTTON_TYPE {
  SIMULATOR = 'simulator',
  TEMPORARY_SAVE = 'temporarySave',
  SAVE = 'save'
}

export enum DRAWER_DATA_TYPE {
  TEMPLATE_NAME = 'templateName',
  CONTENT_BLOCK_FIRST = 'contentBlockFirst',
  CONTENT_BLOCK_LAST = 'contentBlockLast'
}

export enum DRAWER_TYPE {
  FIRST_STEP = '1',
  LAST_STEP = '2'
}

export enum RESERVATION_TYPE {
  RESERVED_REGISTRATION = 'RESERVED_REGISTRATION',
  DESIRE_DATE_REGISTRATION = 'DESIRE_DATE_REGISTRATION'
}

export enum TAB_FILTER_MESSAGES {
  ALL = 'all',
  RESERVATION = 'reservation',
  SUPPORT = 'support'
}

export const MESSAGE_ROOM_EMPTY_FILES = {
  IMAGE: 'đã gửi image',
  FILE: 'đã gửi file'
}

export enum MESSAGE_TYPE {
  CHATBOT = 'chatbot',
  TEXT = 'text',
  IMAGE = 'image',
  FILE = 'file'
}

export enum STORAGE_KEYS {
  ADMIN_ROCKETCHAT = '@admin_rocketchat',
  USER_INFO = '@rocketchat_user_info',
  TENANT = '@tenant'
}

export enum RESERVATION_STATUS {
  COLLECTED_DESIRED_DATE = 'COLLECTED_DESIRED_DATE',
  UNCONFIRMED = 'UNCONFIRMED',
  RESERVED = 'RESERVED',
  CANCELLED = 'CANCELLED'
}

export const ACTIVITY_TYPES = {
  TYPING: 'user-typing'
}

export const FORMAT_DATE = {
  DATE_TIME: 'YYYY-MM-DD HH:mm'
}

export const TENANT_PARAM_KEY = 'tenant'
export const SUB_TENANT_PARAM_KEY = 'subtenant'

export const AllDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

export const DayAbbreviations: Record<string, number> = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6
}

export enum BASIC_INFORMATION_CODE {
  REPORT_CODE = '1.00246',
  CHECKUP_DATE = '1.00003',
  PATIENT_ID = '1.00004',
  FULL_NAME = '1.00005',
  SEX = '1.00011',
  DATE_OF_BIRTH = '1.0001'
}

export enum ITEM_MASTER_CODE {
  CONSULTANT_DATE = '1.00003',
  EVALUATION = '03.0001',
  GENERAL_COMMENTS = '03.0152'
}

export enum TIME_PERIOD {
  MORNING = '午前',
  AFTERNOON = '午後'
}

export const DateFormat = 'YYYY-MM-DD'

export const TEMP_ID = 'tempId'

export const TEXT_COMMENTS = '所見コメント'
export const TEXT_CATEGORY_MASTER = 'カテゴリマスタ'
export const TEXT_TEST_ITEM_MASTER = '検査項目マスター'

export const CSV_DATA = 'csvData'
export const PRV_CSV_PARAM = 'prcsv'
export enum CSV_STATUS_ERROR {
  DUPLICATE,
  FIELD_MISSING
}

export enum ALLERGIC_STATUS {
  NO,
  YES
}

export enum COUNTRY {
  JAPAN = 'jp',
  VIETNAM = 'vn'
}
