import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, NavLink, Navigate, Outlet, useLocation } from 'react-router-dom'

import { Flex, Layout, Typography } from 'antd'
import i18n from 'i18next'

import { useAuth } from '../../context/AuthProvider'
import {
  useRolePermissions,
  useUserInfo
} from '../../hooks/role-permission/usePermissions'
import styles from './HomeLayout.module.scss'
import { GlobalOutlined } from '@ant-design/icons'
import loadable from '@loadable/component'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { ReactComponent as AvatarNoImageIcon } from 'assets/svgs/avatarNoImageIcon.svg'
import { ASSESSMENT_COMMENT } from 'configs/pathRouter'
import useBasicSetting from 'hooks/useBasicSetting'
import useClickOutSide from 'hooks/useClickOutSide'
import PdfPreLoad from 'pages/CustomerDetail/components/modals/exportPdf/PdfPreLoad'
import { StringKeyObject } from 'types/common'
import { checkIsTrue } from 'utilities/helpers'

const { Sider, Content } = Layout

const ProductManagement = loadable(() => import('pages/ProductManagement'))
const ReservationManagement = loadable(
  () => import('pages/ReservationManagement')
)
const CustomerManagement = loadable(() => import('pages/CustomerManagement'))
const ConsultantManagement = loadable(
  () => import('pages/ConsultantManagement')
)
const SurveyList = loadable(() => import('pages/SurveyList'))
const YearlyConsultationReport = loadable(
  () => import('pages/YearlyConsultationReport')
)
const Chat = loadable(() => import('pages/Chat/Chat'))
const ScenarioCreate = loadable(() => import('pages/Scenario/ScenarioCreate'))
const ScenarioList = loadable(() => import('pages/Scenario/ScenarioList'))
const AdminList = loadable(() => import('pages/AdminList'))
const ProductMaster = loadable(() => import('pages/ProductMaster'))
const BasicSetting = loadable(() => import('pages/BasicSetting'))
const JudgementSetting = loadable(() => import('pages/JudgementSetting'))
const CsvImportResults = loadable(() => import('pages/CsvImportResults'))

interface Props {
  redirectPath?: string
}

let languagesEnum = [
  {
    label: '日本語',
    language: 'ja'
  },
  {
    label: 'Tiếng Việt',
    language: 'vi'
  },
  {
    label: 'English',
    language: 'en'
    // disable: true
  }
]

const HomeLayout = ({ redirectPath = '/login' }: Props) => {
  const { show, setShow, nodeRef } = useClickOutSide()
  const { tenant } = useBasicSetting()
  const { t } = useTranslation()
  const auth = useAuth()
  const location = useLocation()
  const { hasPermissions } = useRolePermissions()
  useUserInfo()
  const [currentLanguage, setCurrentLanguage] = React.useState(
    i18n.language ?? 'ja'
  )

  useEffect(() => {
    i18n.changeLanguage(currentLanguage)
    const languageClass = `language-${i18n.language}`
    document.body.classList.remove('language-vi', 'language-ja')
    document.body.classList.add(languageClass)
  }, [currentLanguage])

  const handleChangeLanguage = (language: string) => {
    setCurrentLanguage(language)

    setShow(false)
  }

  const getTextLanguage = () => {
    let language = languagesEnum.filter(
      (item: any) => item.language === currentLanguage
    )

    return <>{language?.[0]?.label ?? ''}</>
  }

  if (!auth?.token) {
    return <Navigate to={redirectPath} replace />
  }

  function preloadComponent(Component: any) {
    Component.preload()
  }

  let menus: any = [
    {
      key: 1,
      label: t('menu.productManagement'),
      permission: 'setting_product',
      href: '/product-management',
      disable: false,
      onMouseEnter: () => preloadComponent(ProductManagement)
    },
    {
      key: 2,
      label: t('menu.reservationManagement'),
      permission: 'reservation_management',
      href: '/reservation-management',
      disable: false,
      onMouseEnter: () => preloadComponent(ReservationManagement)
    },
    {
      key: 3,
      label: t('menu.customer'),
      permission: 'customer_management',
      href: '/',
      disable: false,
      onMouseEnter: () => preloadComponent(CustomerManagement)
    },
    {
      key: 4,
      label: t('menu.testResultList'),
      href: '/customer-management',
      permission: 'customer_management',
      second: true,
      onMouseEnter: () => preloadComponent(CustomerManagement)
    },
    {
      key: 5,
      label: t('menu.answerList'),
      href: '/consultant-management',
      permission: 'medical_survey_feedback',
      second: true,
      onMouseEnter: () => preloadComponent(ConsultantManagement)
    },
    {
      key: 6,
      label: t('menu.consultant'),
      permission: 'medical_survey',
      href: '/survey-list',
      onMouseEnter: () => preloadComponent(SurveyList)
    },
    {
      key: 7,
      label: t('menu.setting'),
      href: '/setting',
      permission: '',
      disable: true,
      onMouseEnter: () => preloadComponent(BasicSetting)
    },
    {
      key: 8,
      label: t('menu.comments'),
      href: `/${ASSESSMENT_COMMENT}`,
      permission: '',
      second: true,
      onMouseEnter: () => preloadComponent(JudgementSetting)
    },
    {
      key: 9,
      label: t('menu.basicSetting'),
      href: '/basic-setting',
      permission: '',
      second: true,
      onMouseEnter: () => preloadComponent(BasicSetting)
    },
    {
      key: 10,
      label: t('menu.inspectionSetting'),
      href: '/inspection-item-settings',
      permission: 'setting_inspection',
      second: true
    },
    {
      key: 11,
      label: t('menu.staffManagement'),
      permission: 'setting_role',
      href: '/admin-list',
      onMouseEnter: () => preloadComponent(AdminList)
    },
    {
      key: 12,
      label: t('menu.productMaster'),
      permission: 'setting_product',
      href: '/product-master',
      onMouseEnter: () => preloadComponent(ProductMaster)
    },
    {
      key: 13,
      label: t('menu.consultationReport'),
      permission: 'health_support_claim',
      href: '/yearly-consultation-report',
      disable: false,
      onMouseEnter: () => preloadComponent(YearlyConsultationReport)
    },
    {
      key: 16,
      label: t('menu.chat.title'),
      permission: '',
      href: '/chat',
      onMouseEnter: () => preloadComponent(Chat)
    },
    {
      key: 14,
      label: t('menu.chat.chatBot'),
      permission: '',
      href: '/scenario-management',
      hrefSecond: '/scenario',
      onMouseEnter: () => preloadComponent(ScenarioCreate)
    },
    {
      key: 15,
      label: t('menu.chat.scenario'),
      permission: '',
      href: '/scenario',
      second: true,
      onMouseEnter: () => preloadComponent(ScenarioList)
    },
    ...(!checkIsTrue(tenant?.additionalInfo?.autoJudgement)
      ? [
          {
            key: 16,
            label: t('menu.dataUpload'),
            permission: '',
            href: '/csv-import-results',
            onMouseEnter: () => preloadComponent(CsvImportResults)
          }
        ]
      : [])
  ]

  const activeDefault = ['customer_management', 'medical_survey_feedback']
  const defaultMenuParent = ['/scenario-management']
  let setActive = false
  let defaultHref: any = false

  for (let permission of activeDefault) {
    if (hasPermissions(permission)) {
      setActive = true
      // eslint-disable-next-line no-loop-func
      menus = menus.map((item: any) => {
        if (item?.permission === permission) {
          defaultHref = item.href
          return { ...item, active: true }
        }
        return item
      })

      break
    }
  }

  if (!setActive) {
    menus = menus.map(function (item: any) {
      if (item?.href === '/survey-list') {
        return { ...item, active: true }
      }
      return item
    })
  }

  return (
    <Layout>
      <Sider
        className={styles.container_layout}
        trigger={null}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
          zIndex: 300,
          paddingLeft: 26,
          paddingTop: 20
        }}
      >
        <div className="pl-1 mb-10">
          <Link to={'/my-page'} className="w-max block">
            <AvatarNoImageIcon />
          </Link>
        </div>
        <div className="hidden">
          <PDFDownloadLink document={<PdfPreLoad />}></PDFDownloadLink>
        </div>

        <Flex vertical className="justify-between flex-1">
          <div>
            {menus.map((menu: any, index: any) => {
              const defaultClass: string =
                'font-bold text-[16px] !text-white min-h-[34px] flex items-center pr-1.5'
              const hiddenClass: string = hasPermissions(menu.permission)
                ? 'block'
                : 'hidden'
              const activeClass: string = menu?.active ? 'default-active' : ''
              const disableClass: string = menu?.disable
                ? 'pointer-events-none'
                : ''
              const activatedClass = (
                activated: boolean,
                href: string = ''
              ): string => {
                if (activated) {
                  if (defaultMenuParent.includes(href)) {
                    return 'active-sub-menu pointer-events-none'
                  }
                  if (href === '/') {
                    if (location.pathname === href)
                      return defaultHref
                        ? 'active-sub-menu'
                        : 'active-sub-menu pointer-events-none'

                    return 'pointer-events-none'
                  }

                  return 'active'
                } else {
                  // active survey menu
                  if (
                    href.startsWith('/survey') &&
                    location.pathname.startsWith('/survey')
                  ) {
                    return 'active'
                  }

                  if (href === '/' && location.pathname === href)
                    return defaultHref
                      ? 'active-sub-menu'
                      : 'active-sub-menu pointer-events-none'
                }

                return ''
              }

              const menuTo = (menu: StringKeyObject) => {
                const href = menu.href
                if (defaultMenuParent.includes(href)) {
                  return menu.hrefSecond ?? '/'
                }

                return href !== '/' ? href : (defaultHref ?? '/')
              }

              return menu?.second && menu.second ? (
                <NavLink
                  key={index}
                  to={menu.href}
                  className={`${defaultClass} ${hiddenClass} ${activeClass} pl-3`}
                  onMouseEnter={menu.onMouseEnter}
                >
                  - {menu.label}
                </NavLink>
              ) : (
                <NavLink
                  key={index}
                  to={menuTo(menu)}
                  className={({ isActive }) =>
                    `${defaultClass} ${hiddenClass} ${activeClass} ${disableClass} ${activatedClass(isActive, menu.href)} pl-1 block`
                  }
                  onMouseEnter={menu.onMouseEnter}
                >
                  {menu.label}
                </NavLink>
              )
            })}
          </div>
          <Flex
            align="center"
            gap={8}
            className="ml-[-26px] px-4 py-2 bg-[rgba(255,255,255,0.1)] cursor-pointer mb-40 relative mt-20"
            onClick={() => setShow(!show)}
            ref={nodeRef}
          >
            <GlobalOutlined className="text-white" />
            <Typography className="text-white">{getTextLanguage()}</Typography>

            <div
              className={`absolute max-h-[120px] overflow-y-auto top-full left-0 w-full rounded-sm bg-[rgba(255,255,255,0.1)] z-10 ${
                show ? '' : 'hidden'
              }`}
            >
              {languagesEnum.map((item, idx) => (
                <div
                  key={idx}
                  className="px-4 py-2 cursor-pointer hover:bg-[#b6cedf] text-white"
                  onClick={() => {
                    // if (item.disable) return
                    handleChangeLanguage(item.language)
                  }}
                >
                  {item.label}
                </div>
              ))}
            </div>
          </Flex>
        </Flex>
      </Sider>
      <Layout className="bg-[#F0F3F7] ml-[200px]">
        <Content
          style={{
            padding: '20px 10px',
            overflow: 'auto',
            minHeight: '100vh',
            width: '100%'
          }}
        >
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  )
}

export default HomeLayout
